import { forwardRef } from 'react';

import { LocalePrefix } from 'next-intl/dist/types/src/shared/types';
import { type LinkProps } from 'next/link';

import { Link } from '@/navigation';
import { ChildrenProps, ClassNameProps, Locale } from '@/types/common.types';

// =================================================================
export interface ForwardedLinkProps
  extends Omit<LinkProps, 'prefetch'>,
    ChildrenProps,
    ClassNameProps {
  locale?: Locale;
  localePrefix?: LocalePrefix;
  style?: Record<string, any>;
}

// =================================================================

export const ForwardedLink = forwardRef<HTMLAnchorElement, ForwardedLinkProps>((props, ref) => {
  const { children, ...rest } = props;

  return (
    <Link ref={ref} prefetch={false} {...rest}>
      {children}
    </Link>
  );
});

// =================================================================

if (process.env.NODE_ENV !== 'production') {
  ForwardedLink.displayName = 'ForwardedLink';
}
