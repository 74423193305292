import { ForwardedRef, forwardRef } from 'react';

import clsx from 'clsx';

import { Link } from '@/navigation';
import { ChildrenProps, ClassNameProps } from '@/types/common.types';

import styles from '@/components/Buttons/TextButton.module.scss';

// =================================================================

type VariantButton = 'signature' | 'dimmed';

type CommonProps = {
  variant?: VariantButton;
  onClick?: () => void;
  disabled?: boolean;
} & ClassNameProps &
  Partial<ChildrenProps>;

type TextButtonProps = CommonProps &
  (
    | {
        as: 'button';
        type?: 'button' | 'submit' | 'reset';
        href?: never;
        replace?: never;
      }
    | {
        as: 'link';
        type?: never;
        href: string;
        replace?: boolean;
      }
  );

// =================================================================

export const TextButton = forwardRef<HTMLButtonElement | HTMLAnchorElement, TextButtonProps>(
  (props, ref) => {
    const { as = 'button', type = 'button', href, className, children, ...rest } = props;

    const classes = clsx(styles.textButtonBase, className);

    if (as === 'link' && href) {
      return (
        <Link
          ref={ref as ForwardedRef<HTMLAnchorElement>}
          href={href}
          prefetch={false}
          className={classes}
          {...rest}
        >
          {children}
        </Link>
      );
    }

    if (as === 'button') {
      return (
        <button
          ref={ref as ForwardedRef<HTMLButtonElement>}
          type={type}
          className={classes}
          {...rest}
        >
          {children}
        </button>
      );
    }

    return null;
  },
);

// =================================================================

if (process.env.NODE_ENV !== 'production') {
  TextButton.displayName = 'TextButton';
}
