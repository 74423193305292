import { forwardRef, type JSX, type ForwardedRef, type MouseEventHandler } from 'react';

import clsx from 'clsx';

import { Link } from '@/navigation';
import { ChildrenProps, ClassNameProps, SVGProps } from '@/types/common.types';

import styles from './IconButton.module.scss';

// =================================================================

type IconType = (props: SVGProps) => JSX.Element;

type CommonProps = {
  title?: string;
  inline?: boolean;
  icon: IconType;
  iconClassName?: string;
  onClick?: MouseEventHandler<HTMLButtonElement> | MouseEventHandler<HTMLAnchorElement>;
  iconWidth?: number | string;
  iconHeight?: number | string;
  disabled?: boolean;
} & ClassNameProps &
  Partial<ChildrenProps>;

export type IconButtonProps = CommonProps &
  (
    | {
        as: 'button';
        type?: 'button' | 'submit' | 'reset';
        href?: never;
        replace?: never;
      }
    | {
        as: 'link';
        type?: never;
        href: string;
        replace?: boolean;
      }
  );

// =================================================================

export const IconButton = forwardRef<HTMLButtonElement | HTMLAnchorElement, IconButtonProps>(
  (props, ref) => {
    const {
      title,
      children,
      icon: Icon,
      iconClassName,
      className,
      iconWidth,
      iconHeight,
      onClick,
      inline = false,
      as,
      ...rest
    } = props;

    const classes = clsx(
      {
        [styles.iconButtonBase]: true,
        [styles.inline]: inline,
        [styles.disabled]: props.disabled,
      },
      className,
    );

    const iconClasses = clsx(
      {
        [styles.iconMarginRight]: children,
      },
      iconClassName,
    );

    if (as === 'button') {
      return (
        <button
          ref={ref as ForwardedRef<HTMLButtonElement>}
          type={props.type || 'button'}
          onClick={onClick as MouseEventHandler<HTMLButtonElement>}
          title={title}
          className={classes}
          {...rest}
        >
          <Icon className={iconClasses} width={iconWidth} height={iconHeight} />
          {children}
        </button>
      );
    }

    if (as === 'link') {
      return (
        <Link
          title={title}
          ref={ref as ForwardedRef<HTMLAnchorElement>}
          href={props.href}
          replace={props.replace}
          onClick={onClick as MouseEventHandler<HTMLAnchorElement>}
          className={classes}
          prefetch={false}
          {...rest}
        >
          <Icon className={iconClasses} width={iconWidth} height={iconHeight} />
          {children}
        </Link>
      );
    }

    return null;
  },
);

// =================================================================

if (process.env.NODE_ENV !== 'production') {
  IconButton.displayName = 'IconButton';
}
