/**
 * @url https://next-intl-docs.vercel.app/docs/getting-started/app-router/with-i18n-routing#i18nts
 */
import { getRequestConfig } from 'next-intl/server';
import { notFound } from 'next/navigation';

import type { Locale } from '@/types/common.types';

export const locales = ['en', 'ru', 'uz'] as const;
export const defaultLocale = 'ru' as const;

export default getRequestConfig(async ({ locale }) => {
  if (!locales.includes(locale as Locale)) notFound();

  return {
    messages: (await import(`../dictionaries/${locale}.json`)).default,
  };
});
