'use client';

import { useState, useRef, useEffect } from 'react';

import defer from 'lodash/defer';

export const useHover = <ElementRef extends HTMLElement>() => {
  const [isHovered, setIsHovered] = useState(false);

  const ref = useRef<ElementRef | null>(null);

  useEffect(() => {
    let timeoutMouseEnter: number | undefined;
    let timeoutMouseLeave: number | undefined;

    const handleMouseEnter = () => {
      timeoutMouseEnter = defer(setIsHovered, true);
    };

    const handleMouseLeave = () => {
      timeoutMouseLeave = defer(setIsHovered, false);
    };

    const element = ref.current;

    if (element) {
      element.addEventListener('mouseenter', handleMouseEnter);
      element.addEventListener('mouseleave', handleMouseLeave);

      return () => {
        element.removeEventListener('mouseenter', handleMouseEnter);
        element.removeEventListener('mouseleave', handleMouseLeave);

        if (timeoutMouseEnter) {
          window.clearTimeout(timeoutMouseEnter);
        }

        if (timeoutMouseLeave) {
          window.clearTimeout(timeoutMouseLeave);
        }
      };
    }
  }, []);

  return { ref, isHovered };
};
