import { ForwardedRef, forwardRef } from 'react';

import cn from 'classnames';

import { Link } from '@/navigation';
import { ChildrenProps, ClassNameProps } from '@/types/common.types';

import styles from '@/components/Buttons/SolidButton.module.scss';

// =================================================================

type ButtonVariant = 'signature' | 'dimmed';
type ButtonSize = 'auto' | 'full';

type CommonProps = {
  variant?: ButtonVariant;
  onClick?: () => void;
  size?: ButtonSize;
  disabled?: boolean;
} & ClassNameProps &
  Partial<ChildrenProps>;

type SolidButtonProps = CommonProps &
  (
    | {
        as: 'button';
        type?: 'button' | 'submit' | 'reset';
        href?: never;
        replace?: never;
      }
    | {
        as: 'link';
        type?: never;
        href: string;
        replace?: boolean;
      }
  );

// =================================================================

export const SolidButton = forwardRef<HTMLButtonElement | HTMLAnchorElement, SolidButtonProps>(
  (props, ref) => {
    const {
      as = 'button',
      type = 'button',
      variant = 'signature',
      size = 'auto',
      className,
      children,
      href,
      ...rest
    } = props;

    const classes = cn(styles.solidButtonBase, styles[variant], styles[size], className);

    if (as === 'link' && href) {
      return (
        <Link
          ref={ref as ForwardedRef<HTMLAnchorElement>}
          href={href}
          prefetch={false}
          className={classes}
          {...rest}
        >
          {children}
        </Link>
      );
    }

    if (as === 'button') {
      return (
        <button
          ref={ref as ForwardedRef<HTMLButtonElement>}
          type={type}
          className={classes}
          {...rest}
        >
          {children}
        </button>
      );
    }

    return null;
  },
);

// =================================================================

if (process.env.NODE_ENV !== 'production') {
  SolidButton.displayName = 'SolidButton';
}
