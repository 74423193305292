import { createElement, memo } from 'react';

import { ForwardedLink } from '../ForwardedLink';
import clsx from 'clsx';

import { ChildrenProps, ClassNameProps } from '@/types/common.types';

import styles from '@/components/TruncatedText/TruncatedText.module.scss';

type CommonProps = {
  lines?: number;
} & ChildrenProps &
  ClassNameProps;

export type TruncatedTextProps = CommonProps &
  (
    | { as?: 'link'; href: URL | string }
    | {
        as?: 'p' | 'div' | 'span' | 'h3' | 'h1';
        href?: never;
      }
  );

export const TruncatedText = memo((props: TruncatedTextProps) => {
  const { lines = 2, children, className } = props;

  if (props.as === 'link') {
    return (
      <ForwardedLink
        href={props.href}
        style={{ WebkitLineClamp: lines }}
        className={clsx(styles.truncatedText, className)}
      >
        {children}
      </ForwardedLink>
    );
  }

  return createElement(
    props.as || 'p',
    { className: clsx(styles.truncatedText, className), style: { WebkitLineClamp: lines } },
    children,
  );
});

// =================================================================

if (process.env.NODE_ENV !== 'production') {
  TruncatedText.displayName = 'TruncatedText';
}
