import axios from 'axios';
import Cookie from 'js-cookie';

import { applyExtractorResponseInterceptor } from '@/libs/http-client/interceptors/extractor-response.interceptor';

import { NEXT_LOCALE } from '@/constants/user-credentials.constants';

// ----------------------------------------------------------------------

export const httpClient = axios.create({
  baseURL: process.env.NEXT_PUBLIC_CINERAMA_API_BASE,
  timeout: 30 * 1000,
  withCredentials: false,
  headers: {
    'CN-API-SYSTEM': 'web',
    'CN-API-TYPE': 'web',
    'CN-APP-VERSION': process.env.NEXT_PUBLIC_CINERAMA_APP_VERSION,
    Locale: Cookie.get(NEXT_LOCALE),
  },
});

// ----------------------------------------------------------------------

applyExtractorResponseInterceptor(httpClient);
