/**
 * @url https://next-intl-docs.vercel.app/docs/routing/navigation
 */
import { LocalePrefix } from 'next-intl/dist/types/src/shared/types';
import { createSharedPathnamesNavigation } from 'next-intl/navigation';

import { locales } from '@/i18n';

export const localePrefix: LocalePrefix = 'always'; // Default

export const { Link, redirect, usePathname, useRouter } = createSharedPathnamesNavigation({
  locales,
  localePrefix,
});
