import { ForwardedRef, MutableRefObject, RefCallback, useMemo } from 'react';

// ----------------------------------------------------------------------

type Ref<Value> = RefCallback<Value> | MutableRefObject<Value> | ForwardedRef<Value> | undefined;

const setRef = <Value>(ref: Ref<Value>, value: Value) => {
  if (typeof ref === 'function') {
    ref(value);
  } else if (ref) {
    ref.current = value;
  }
};

// ----------------------------------------------------------------------

export const useMergeRefs = <Value>(refA: Ref<Value>, refB: Ref<Value>) => {
  return useMemo(() => {
    if (!refA && !refB) {
      return null;
    }

    return (refValue: Value) => {
      setRef(refA, refValue);
      setRef(refB, refValue);
    };
  }, [refA, refB]);
};
